import React from "react";
import NotFoundImage from "../../assets/images/404.gif";

const NotFound = () => {
    document.title = `404 - Not Found`;

    return <div className={`h-100 d-flex align-items-center justify-content-center flex-column`}>
        <img src={NotFoundImage} alt={`404 Not Found`} width={125}/>
        <div className={`text-center`}>
            <h2>Page Not Found!</h2>
            <hr/>
            <p>Oops! Looks like you have wandered into an unknown page.</p>
            <p>Please contact your admin for more help.</p>
        </div>
    </div>
};

export default NotFound;
