/* Colors */

export const colors = ["#B5C0D0", "#CCD3CA", "#F5E8DD", "#EED3D9", "#B2C8BA", "#ADC4CE", "#E4D0D0"];

/* Array */

export const returnRandomArrayItem = (arr: string[]) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
};

/* URL Links */

export const openWhatsApp = (num: number) => {
    window.open(`https://wa.me/${num}`);
}


/* Blood Groups */

export enum BloodGroupEnum {
    "O_Positive" = "O+",
    "O_Negative" = "O-",
    "A_Positive" = "A+",
    "A_Negative" = "A-",
    "B_Positive" = "B+",
    "B_Negative" = "B-",
    "AB_Positive" = "AB+",
    "AB_Negative" = "AB-"
}

export interface BloodGroup {
    label: BloodGroupEnum,
    value: string
}

export const bloodGroups: BloodGroup[] = [{
    label: BloodGroupEnum.O_Positive,
    value: "1"
}, {
    label: BloodGroupEnum.O_Negative,
    value: "2"
}, {
    label: BloodGroupEnum.A_Positive,
    value: "3"
}, {
    label: BloodGroupEnum.A_Negative,
    value: "4"
}, {
    label: BloodGroupEnum.B_Positive,
    value: "5"
}, {
    label: BloodGroupEnum.B_Negative,
    value: "6"
}, {
    label: BloodGroupEnum.AB_Positive,
    value: "7"
}, {
    label: BloodGroupEnum.AB_Negative,
    value: "8"
}];


/* Gender */

export enum GenderEnum {
    male = "Male",
    female = "Female"
}

interface GendersInterface {
    label: GenderEnum,
    value: GenderEnum
}

export const genders: GendersInterface[] = [{
    label: GenderEnum.male,
    value: GenderEnum.male
}, {
    label: GenderEnum.female,
    value: GenderEnum.female
}]


/* Marital Status */
export const formattedMaritalStatus = (value: string) => {
    switch (value) {
        case "1": return "Single";
        case "2": return "Engaged";
        case "3": return "Married";
        case "4": return "Divorced";
        case "5": return "Widowed";
        case "6": return "Separated";
        default: return "";
    }
};

export const formattedGender = (value: string) => {
    switch (value) {
        case "1": return "Male";
        case "2": return "Female";
        default: return "";
    }
};

export const formattedBloodGroup = (value: string) => {
    switch (value) {
        case "0": value = ""; break;
        case "1": value = "O+"; break;
        case "2": value = "O-"; break;
        case "3": value = "A+"; break;
        case "4": value = "A-"; break;
        case "5": value = "B+"; break;
        case "6": value = "B-"; break;
        case "7": value = "AB+"; break;
        case "8": value = "AB-"; break;
    }

    return value;
}
