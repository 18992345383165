import React, {useEffect} from "react";
import {Container} from "reactstrap";
import Dashboard from "../dashboard";
import {useNavigate} from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/members");
    }, []);

    return <Container fluid>
        <Dashboard />
    </Container>
};

export default Home;
