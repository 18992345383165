const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
//const PY_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const LOGIN = `${ BASE_URL }/login-otp.php`;
export const TOKEN_AUTH = `${ BASE_URL }/login-otp.php`;
export const POLLING = `${ BASE_URL }/polling/controller.php`;
export const POLLING_QUESTIONS = `${ BASE_URL }/questions/controller.php`;
export const POLLING_ANSWERS = `${ BASE_URL }/answers/controller.php`;
export const REGISTRATION = `${ BASE_URL }/register/controller.php`;
export const CLUB_DETAILS = `${ BASE_URL }/clubDetails/controller.php`;
export const CLUB_MODULES = `${ BASE_URL }/modules/controller.php`;
export const MEMBERS = `${ BASE_URL }/members/controller.php`;
export const EXPORT = `${ BASE_URL }/export/controller.php`;
