import {_get} from "../../helpers/FetchWrapper";
import {REGISTRATION} from "../../config/apis";

export interface ClubDetails {
    ClubName: string
    Domain: string
    RegId: string
}

export const GetRegistrationClubDetails = async (params: string) => {
    return await _get(`${REGISTRATION}${params}`);
};
