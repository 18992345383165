import React, {useState} from "react";
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Navbar as ReactNavbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import navbarStyles from "./navbar.module.scss";
import LogoIcon from "../../assets/images/logo.png";
import RootStore from "../../store/store.types";
import {pageRoutes} from "../../config/pages";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const modules = useSelector((state: RootStore) => state.home.modules.list);
    const location = useLocation();

    return <ReactNavbar className={classnames(navbarStyles.navbar)} transition="false">
        <NavbarBrand
            className="me-auto"
            tag={Link}
            to="/"
        >
            <img src={LogoIcon} alt={"CMAC Logo"} width={34}/>
        </NavbarBrand>
        <NavbarToggler className={classnames(navbarStyles.navbar__toggler, "me-2")} onClick={() => {
            setIsOpen(!isOpen);
        }}>

        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar transition='false'>
            <Nav navbar>
                {
                    modules.filter(module => {
                        return module.IsActive === "1" && pageRoutes?.[module.ModuleId]
                    }).map((module) => {
                        return <NavItem key={module.ModuleId} className={classnames(navbarStyles.navbar__item)}>
                            <NavLink tag={Link} to={`${pageRoutes?.[module.ModuleId]}`} className={classnames(navbarStyles.navbar__link, location.pathname.indexOf(`${module.ModuleId}`) > -1 ? navbarStyles.navbar__item_active : '')}>
                                { module.ModuleName }
                            </NavLink>
                        </NavItem>
                    })
                }
                <NavItem className={classnames(navbarStyles.navbar__item)}>
                    <NavLink tag={Link} to={`/profile`} className={classnames(navbarStyles.navbar__link, location.pathname.indexOf(`profile`) > -1 ? navbarStyles.navbar__item_active : '')}>
                        Profile
                    </NavLink>
                </NavItem>
                <NavItem className={classnames(navbarStyles.navbar__item)}>
                    <NavLink tag={Link} to={`/logout`} className={classnames(navbarStyles.navbar__link, location.pathname.indexOf(`logout`) > -1 ? navbarStyles.navbar__item_active : '')}>
                        Logout
                    </NavLink>
                </NavItem>
            </Nav>
        </Collapse>
    </ReactNavbar>
};

export default Navbar;
