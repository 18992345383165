import React from "react";
import classNames from "classnames";
import styles from "./style.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

interface FilterProps {
    children: React.ReactElement | string;
    active?: boolean;
    onClick?: () => void;
    className?: string;
}

const Filter = (props: FilterProps) => {
    const { active = false, className = "", children } = props;

    return <span className={classNames(styles.filter__item, active && styles.filter__item_active, className)}
                 onClick={props?.onClick}>
        {active && <FontAwesomeIcon icon={faTimes} className={`me-1`} />}
        {children}
    </span>
};

export default Filter;
