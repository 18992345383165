import React, {Fragment, useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import routes from "./config/routes";
import PrivateRoutes from "./helpers/PrivateRoutes";
import PageLoader from "./components/loader";
import RootStore from "./store/store.types";
import {getCookie} from "./helpers/CookieWrapper";
import {validateToken, toggleAuthenticationState} from "./screens/login/login.slice";
import {getClubDetails} from "./screens/home/home.slice";
import Navbar from "./components/navbar";
import Sidebar from "./components/sidebar";
import classNames from "classnames";
import pageStyles from "./index.module.scss";
import Offline from "./components/offline";
import './App.scss';

const App = () => {
  const dispatch = useDispatch();

  /* Redux selectors */
  const authenticatedStateLoading = useSelector((state: RootStore) => state.login.user.authentication_loading);
  const authenticatedState = useSelector((state: RootStore) => state.login.user.authenticated);
  const userDetails = useSelector((state: RootStore) => state.login.user.details);

  /* States */
  const [isOnline, setIsOnline] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    const token = getCookie("token");
    if(token) {
      dispatch(validateToken({
        "payload": ""
      }) as any);
    } else {
      dispatch(toggleAuthenticationState(false));
    }
  }, []);
  useEffect(() => {
    if(authenticatedState) {
      dispatch(getClubDetails(userDetails.regId) as any);
    }

    if(!authenticatedStateLoading) {
      setPageLoading(false);
    }
  }, [authenticatedStateLoading]);

  /* Check online status of the app */
  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);

  if(pageLoading) {
    return <PageLoader text={`Authenticating`} />
  }

  return (
    <div className="App">
      { !isOnline && <Offline /> }
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => (
              <Route
                  key={index}
                  path={route.path}
                  element={
                    route.protected ? (
                        <PrivateRoutes>
                          <div className={classNames(pageStyles.page_wrapper)}>
                            <Sidebar/>
                            <div className={classNames(pageStyles.page_layout)}>
                              <Navbar/>
                              <div className={classNames(pageStyles.container)}>
                                {route.component}
                              </div>
                            </div>
                          </div>
                        </PrivateRoutes>
                    ) : (
                        <Fragment>
                          {route.component}
                        </Fragment>
                    )
                  }
              />
          ))}
          <Route path="*" element={<Navigate to="/404" replace/>}/>
        </Routes>
      </BrowserRouter>
      <ToastContainer theme={'colored'}/>
    </div>
  );
}

export default App;
