import {_get, _delete} from "../../helpers/FetchWrapper";
import {EXPORT, MEMBERS} from "../../config/apis";

export interface MemberItem {
    Extra1: string
    Extra2: string
    Extra3: string
    ExtraData: string
    FamilyId: number
    HasPartner: "1" | "2" | "3" | "4" | "5"
    ImageUrl: string
    MemberAdmin: "1" | "2"
    MemberId: number
    MemberUrl: string
    MembershipNo: string
    Mobile: number
    Name: string
    NamePrefix: string
    SpouseAdmin: "1" | "2"
    SpouseExtraData: string
    SpouseId: number
    SpouseMobile: number
    SpouseName: string
    SpouseNamePrefix: string
    LastLogin: string
}

export interface MemberDetailsItem {
    BloodGroup: "" | "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8";
    BusinessType: string;
    CatalogueUrl: string;
    CoverUrl?: string;
    DOB: string;
    DateOfMarriage: string;
    Email: string;
    Email1: string;
    Extra1?: string;
    Extra2?: string;
    Extra3?: string;
    FacebookUrl: string;
    FamilyId: string;
    Food?: string;
    Gender: "0" | "1" | "2";
    GuardianName: string;
    MotherName: string;
    HasChildren: "1" | "2";
    HasPartner: "1" | "2" | "3" | "4" | "5" | "6"; // Single / Engaged / Married / Divorced / Widowed / Separated
    Hobbies: string;
    HomeAddress?: string;
    HomeAreaCode?: string;
    HomeCentrex?: string;
    HomeCityCode?: string;
    HomePhone?: string;
    HomePincode?: string;
    HomeStateCode?: string;
    ImageUrl?: string;
    InlawAddress1?: string;
    InlawAddress2?: string;
    InlawAddress3?: string;
    InlawAddress4?: string;
    InlawAddress5?: string;
    InlawName?: string;
    LinkedinUrl: string;
    InstagramUrl: string;
    YouTubeUrl: string;
    MemberId: string;
    MemberJoiningDate?: string;
    MembershipNo?: string;
    MembershipType?: string;
    Mobile: string;
    Mobile1: string;
    Mobile2: string;
    Name: string;
    NativePlace: string;
    Occupation: string;
    OfficeAddress: string;
    OfficeAreaCode: string;
    OfficeCentrex: string;
    OfficeCityCode: string;
    OfficePhone: string;
    OfficePincode: string;
    OfficeStateCode: string;
    Recognition: string;
    Sports: string;
    RegId?: string;
    Religion?: string;
    TwitterUrl: string;
    WebsiteUrl: string;
    AadhaarId: string;
    Relation: string;
    posts?: {
        FromTerm: string
        ToTerm: string
        Post: string
    }[]
    Matrimonial: null | "1"; // null - False | 1 - True
}

export const getMembersService = async (params: string) => {
    return await _get(`${MEMBERS}${params}`);
};

export const downloadMembersList = async (params: string) => {
    return await _get(`${EXPORT}${params}`);
};

export interface DeleteMemberPayload {
    FamilyId: string
    Type: number
}

export const deleteMemberService = async (payload: DeleteMemberPayload) => {
    return await _delete(`${MEMBERS}`, payload)
};
