import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import {Formik} from "formik";
import Yup from "yup";

const MemberForm = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [formType, setFormType] = useState("POST");

    useEffect(() => {
        if(params.familyId) {
            setFormType("PUT");
        }
    }, []);

    return  <Container>
        <Row>

        </Row>
    </Container>
};

export default MemberForm;
