import React, {Fragment} from "react";
import {MemberDetailsItem} from "../../../../services/members";
import Logo from "../../../../assets/images/logo.png";
import classNames from "classnames";
import {PhotoProvider, PhotoView} from "react-photo-view";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBaseballBatBall, faBowlFood, faBriefcase, faBuilding,
    faCake, faCalendar, faCity, faDroplet,
    faEnvelope, faGift,
    faGlobe, faGuitar, faHeart, faHome, faInfo, faMars, faMedal,
    faMessage,
    faPhone, faPhoneAlt, faUser, faUserEdit, faUserGroup,
    faUserShield, faVenus,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import styles from "./style.module.scss";
import {
    faFacebook,
    faInstagram,
    faLinkedinIn,
    faTwitter,
    faWhatsapp,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import Moment from "moment-timezone";
import {formattedBloodGroup, formattedGender, formattedMaritalStatus} from "../../../../helpers/Constants";
import {Button} from "reactstrap";

interface IndividualMemberProps {
    item: MemberDetailsItem;
}

const IndividualMember = ({ item }: IndividualMemberProps) => {
    let sectionHeader = item?.Relation || "Child Details";
    if (item?.MemberId === "1") {
        sectionHeader = "Member Details";
    }
    if (item?.MemberId === "2") {
        sectionHeader = "Spouse Details";
    }

    const renderActionSection = () => {
        if(item.Mobile || item.Email) {
            return <Fragment>
                <div className={classNames(styles.member__action)}>
                    {item.Mobile && renderActionItem(faPhone, `Call`, `tel:${item.Mobile}`)}
                    {item.Mobile && renderActionItem(faMessage, `SMS`, `sms:${item.Mobile}`)}
                    {item.Mobile && renderActionItem(faWhatsapp, `WhatsApp`, `https://wa.me/91${item?.Mobile}`)}
                    {item.Email && renderActionItem(faEnvelope, `Email`, `mailto:${item.Email}`)}
                </div>
                <hr/>
            </Fragment>
        }

        return <></>
    };
    const renderActionItem = (icon: IconDefinition, label: string, link: string) => {
        return <a className={classNames(styles.member__action_item)} href={link} target={`_blank`}>
            <FontAwesomeIcon icon={icon}/>
            <p>{label}</p>
        </a>
    };

    const renderDetailsItem = (icon: IconDefinition, label: string, value: string = "", link: string = "") => {
        if(!value) {
            return <></>;
        }

        const content = <Fragment>
            <div className={classNames(styles.member__details_item_icon)}>
                <FontAwesomeIcon icon={icon}/>
            </div>
            <div>
                <small className={classNames(styles.member__details_item_label, "text-muted")}>{label}</small>
                <p className={classNames(styles.member__details_item_value)}>{value}</p>
            </div>
        </Fragment>

        if (link) {
            return <a className={classNames(styles.member__details_item)} href={link || value} target={`_blank`}>
                {content}
            </a>
        }

        return <div className={classNames(styles.member__details_item)}>
            {content}
        </div>
    };
    const renderContactInfoSection = () => {
        if (item?.Mobile || item?.Mobile1 || item?.Mobile2 || item?.Email || item?.Email1) {
            return <div className={classNames(styles.member__details)}>
            <div className={classNames(styles.member__details_title)}>Contact Info</div>
                {item?.Mobile && renderDetailsItem(faPhone, "Mobile", item.Mobile, `tel:${item.Mobile}`)}
                {item?.Mobile1 && renderDetailsItem(faPhone, "Mobile 2", item.Mobile1, `tel:${item.Mobile1}`)}
                {item?.Mobile2 && renderDetailsItem(faPhone, "Mobile 3", item.Mobile2, `tel:${item.Mobile2}`)}
                {item?.Email && renderDetailsItem(faEnvelope, "Email", item.Email, `mailto:${item.Email}`)}
                {item?.Email1 && renderDetailsItem(faEnvelope, "Email", item.Email1, `mailto:${item.Email1}`)}
            </div>
        }

        return <></>;
    };
    const renderSocialInfoSection = () => {
        if(item?.FacebookUrl || item?.TwitterUrl || item?.WebsiteUrl || item?.LinkedinUrl || item?.InstagramUrl || item?.YouTubeUrl) {
            return <div className={classNames(styles.member__details)}>
                <div className={classNames(styles.member__details_title)}>Social Info</div>
                {item?.FacebookUrl && renderDetailsItem(faFacebook, "Facebook", item.FacebookUrl, item.FacebookUrl)}
                {item?.TwitterUrl && renderDetailsItem(faTwitter, "Twitter", item.TwitterUrl, item.TwitterUrl)}
                {item?.WebsiteUrl && renderDetailsItem(faGlobe, "Website", item.WebsiteUrl, item.WebsiteUrl)}
                {item?.LinkedinUrl && renderDetailsItem(faLinkedinIn, "LinkedIn", item.LinkedinUrl, item.LinkedinUrl)}
                {item?.InstagramUrl && renderDetailsItem(faInstagram, "Instagram", item.InstagramUrl, item.InstagramUrl)}
                {item?.YouTubeUrl && renderDetailsItem(faYoutube, "YouTube", item.YouTubeUrl, item.YouTubeUrl)}
            </div>
        }

        return <></>;
    };
    const renderAboutInfoSection = () => {
        if(item?.DOB || item?.HasPartner || item?.DateOfMarriage || item?.BloodGroup || item?.GuardianName || item?.MotherName || (item?.Gender && item?.Gender !== "0") || item?.Recognition || item?.Hobbies || item?.Relation || item?.Sports) {
            return <div className={classNames(styles.member__details)}>
                <div className={classNames(styles.member__details_title)}>About</div>
                {item?.DOB && renderDetailsItem(faCake, "Date of Birth", Moment(item.DOB, "DD/MM/YYYY").format(`MMM DD, YYYY`))}
                {item?.HasPartner && item.MemberId === "1" && renderDetailsItem(item.HasPartner === "1" ? faUser : faUserGroup, "Marital Status", formattedMaritalStatus(item.HasPartner))}
                {item?.DateOfMarriage && item.MemberId === "1" && renderDetailsItem(faGift, "Anniversary Date", Moment(item.DateOfMarriage, "DD/MM/YYYY").format(`MMM DD, YYYY`))}
                {item?.GuardianName && item.MemberId === "1" && renderDetailsItem(faUserShield, "Guardian's Name", item.GuardianName)}
                {item?.MotherName && item.MemberId === "1" && renderDetailsItem(faUserShield, "Mother's Name", item.MotherName)}
                {item?.Gender && renderDetailsItem(item?.Gender === "1" ? faMars : faVenus, "Gender", formattedGender(item.Gender))}
                {item?.BloodGroup && renderDetailsItem(faDroplet, "Blood Group", formattedBloodGroup(item.BloodGroup))}
                {item?.Recognition && renderDetailsItem(faMedal, "Recognition", item.Recognition)}
                {item?.Hobbies && renderDetailsItem(faGuitar, "Hobbies", item.Hobbies)}
                {item?.Relation && renderDetailsItem(faHeart, "Relation", item.Relation)}
                {item?.Sports && renderDetailsItem(faBaseballBatBall, "Sport", item.Sports)}
            </div>
        }

        return <></>
    };
    const renderResidenceSection = () => {
        if(item?.Food || item?.NativePlace || item?.HomePhone || item?.HomeCentrex || item?.HomeAddress || item?.HomeAreaCode || item?.HomePincode || item?.HomeCityCode || item?.HomeStateCode) {
            return <div className={classNames(styles.member__details)}>
                <div className={classNames(styles.member__details_title)}>Residence Details</div>
                {renderDetailsItem(faHome, "Residence", `${item?.HomeAddress ? `${item?.HomeAddress}\n` : ""}${item?.HomeAreaCode ? `${item?.HomeAreaCode}\n` : ""}${item?.HomePincode ? `${item?.HomePincode}\n` : ""}${item?.HomeCityCode ? `${item?.HomeCityCode}\n` : ""}${item?.HomeStateCode ? `${item?.HomeStateCode}` : ""}`)}
                {renderDetailsItem(faPhone, "Home Phone", item?.HomePhone || "")}
                {renderDetailsItem(faPhone, "Home Centrex", item?.HomeCentrex || "")}
                {renderDetailsItem(faBowlFood, "Food Preference", item?.Food || "")}
                {renderDetailsItem(faCity, "Native", item?.NativePlace || "")}
            </div>
        }

        return <></>;
    };
    const renderOfficeSection = () => {
        if(item?.Occupation || item?.BusinessType || item?.OfficeAddress || item?.OfficeAreaCode || item?.OfficePincode || item?.OfficeCityCode || item?.OfficeStateCode || item?.OfficePhone || item?.OfficeCentrex) {
            return <div className={classNames(styles.member__details)}>
                <div className={classNames(styles.member__details_title)}>Professional Details</div>
                {renderDetailsItem(faBriefcase, "Profession", item?.Occupation)}
                {renderDetailsItem(faBuilding, "Office Address", `${item?.BusinessType ? `${item?.BusinessType}\n` : ""}${item?.OfficeAddress ? `${item?.OfficeAddress}\n` : ""}${item?.OfficeAreaCode ? `${item?.OfficeAreaCode}\n` : ""}${item?.OfficePincode ? `${item?.OfficePincode}\n` : ""}${item?.OfficeCityCode ? `${item?.OfficeCityCode}\n` : ""}${item?.OfficeStateCode ? `${item?.OfficeStateCode}` : ""}`)}
                {renderDetailsItem(faHome, "Office Phone", item?.OfficePhone || "")}
                {renderDetailsItem(faHome, "Office Centrex", item?.OfficeCentrex || "")}
            </div>
        }

        return <></>;
    };
    const renderClubSection = () => {
        if(item?.MembershipNo || item?.MembershipType || item?.MemberJoiningDate) {
            return <div className={classNames(styles.member__details)}>
                <div className={classNames(styles.member__details_title)}>Club Details</div>
                {renderDetailsItem(faInfo, "Membership Number", item?.MembershipNo)}
                {renderDetailsItem(faInfo, "Membership Type", item?.MembershipType)}
                {renderDetailsItem(faCalendar, "Joining Date", item?.MemberJoiningDate)}
            </div>
        }

        return <></>;
    };
    const renderInLawDetails = () => {
        if(item?.InlawName || item?.Extra1 || item?.Extra2 || item?.Extra3 || item?.InlawAddress1 || item?.InlawAddress2 || item?.InlawAddress3 || item?.InlawAddress4 || item?.InlawAddress5) {
            return <div className={classNames(styles.member__details)}>
                <div className={classNames(styles.member__details_title)}>In-Law Details</div>
                {renderDetailsItem(faUser, "In-Law Name", item?.InlawName)}
                {renderDetailsItem(faPhone, "In-Law Mobile", item?.Extra1)}
                {renderDetailsItem(faPhoneAlt, "In-Law Phone", item?.Extra2)}
            </div>
        }

        return <></>;
    };

    return <section className={`mb-3`}>
        <h6 className={classNames(styles.member__section)}>{sectionHeader}</h6>
        <div className={`d-flex align-items-center justify-content-between`}>
            <div className={`d-flex align-items-center`}>
                <PhotoProvider>
                    <PhotoView src={item?.ImageUrl || Logo}>
                        <img src={item?.ImageUrl || Logo}
                             alt={`${item.Name}`}
                             width={64}
                             height={64}
                             className={`rounded-circle me-3`}/>
                    </PhotoView>
                </PhotoProvider>
                <div>
                    <h5>{item?.Name}</h5>
                    {item?.Occupation && <p className={classNames(styles.member__sub_text)}>{item?.Occupation || "-"}</p>}
                </div>
            </div>
            <div>
                <Button color={`success`}>
                    <FontAwesomeIcon icon={faUserEdit} /> Edit Details
                </Button>
            </div>
        </div>
        <hr/>
        {renderActionSection()}
        {renderContactInfoSection()}
        {renderSocialInfoSection()}
        {renderAboutInfoSection()}
        {item.MemberId === "1" && renderResidenceSection()}
        {renderOfficeSection()}
        {item.MemberId === "1" && renderClubSection()}
        {item.MemberId === "1" && renderInLawDetails()}
    </section>
};

export default IndividualMember;
