import React, {Fragment} from "react";
import {Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

interface HeaderProps {
    title: string;
    subtitle?: string | React.ReactElement;
    action?: React.ReactElement;
    onBack?: () => void;
}

const PageHeader = (props: HeaderProps) => {
    return <Row className={`align-items-center`}>
        <Col md={6} className={`d-flex align-items-center`}>
            {props.onBack && <div className={`me-3 pointer`} onClick={props.onBack}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </div>}
            <div>
                <h2>{ props.title }</h2>
                {props.subtitle && <Fragment>{ props.subtitle }</Fragment>}
            </div>
        </Col>
        { props.action && <Col md={6} className={`text-end`}>
            { props.action }
        </Col>}
    </Row>
};

export default PageHeader;
