import React from "react";
import classnames from "classnames";
import {color_palette, returnRandomColor} from "../../../helpers/ColorPalette";
import styles from "./name-prefix.module.scss";

interface NamePrefixProps {
    text: string;
    randomBackground?: boolean;
    active?: boolean;
}

const NamePrefix = (props: NamePrefixProps) => {
    const { randomBackground = true, active = false } = props;

    return <span
        className={classnames(styles.prefix, active ? styles.prefix_active : '')}
        style={(randomBackground && !active) ? {
            background: returnRandomColor(color_palette)
        } : {}}>
        {props.text.substring(0, 2).toUpperCase() || "-"}
    </span>
};

export default NamePrefix;
