import React from "react";
import styles from "./style.module.scss";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWifi3, faSlash} from "@fortawesome/free-solid-svg-icons";

const Offline = () => {
    return <div className={classNames(styles.state_offline)}>
        <span className={`fa-stack fa-1x`}>
            <FontAwesomeIcon icon={faWifi3} className={`fa-stack-1x`} />
            <FontAwesomeIcon icon={faSlash} className={`fa-stack-1x`} />
        </span>
         You are currently Offline. Please check your internet connection.
    </div>
};

export default Offline;
