import {_get} from "../../helpers/FetchWrapper";
import {CLUB_DETAILS, CLUB_MODULES} from "../../config/apis";

export const GetClubDetails = async (regId: number) => {
    return await _get(CLUB_DETAILS + `?type=1&regId=${regId}`);
}

export const GetClubModules = async () => {
    return await _get(CLUB_MODULES);
}
