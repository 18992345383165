import React from "react";
import Login from "../screens/login";
import Logout from "../screens/logout";
import Home from "../screens/home";
import PollingForm from "../components/polling/form";
import Registration from "../screens/registration";
import NotFound from "../screens/404";
import Members from "../screens/modules/members/list";
import MemberDetails from "../screens/modules/members/details";
import MemberForm from "../screens/modules/members/form";

interface RouteType {
    path: string;
    component: React.ReactElement;
    name: string;
    protected: boolean;
}

const routes: RouteType[] = [
    {
        path: "/",
        component: <Home />,
        name: "Login Screen",
        protected: true,
    },
    {
        path: "/login",
        component: <Login />,
        name: "Login Screen",
        protected: false,
    },
    {
        path: "/poll/:poll_id",
        component: <PollingForm />,
        name: "Polling Form",
        protected: true,
    },
    {
        path: "/logout",
        component: <Logout />,
        name: "Logout",
        protected: true,
    },
    {
        path: "/registration/:club_domain",
        component: <Registration />,
        name: "Registration",
        protected: false
    },
    {
        path: "/404",
        component: <NotFound />,
        name: "Not Found",
        protected: false
    },
    {
        path: "/members",
        component: <Members />,
        name: "Members",
        protected: true
    },
    {
        path: "/members/add",
        component: <MemberForm />,
        name: "Members",
        protected: true
    }, {
        path: "/members/:familyId",
        component: <MemberDetails />,
        name: "Members",
        protected: true
    }, {
        path: "/members/:familyId/:memberId/modify",
        component: <MemberForm />,
        name: "Members",
        protected: true
    }
];

export default routes;
