import { configureStore } from '@reduxjs/toolkit';
import LoginSlice from "../screens/login/login.slice";
import HomeSlice from "../screens/home/home.slice";

export default configureStore({
    reducer: {
        login: LoginSlice,
        home: HomeSlice
    },
});
