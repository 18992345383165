import React, { useState, useEffect, Fragment } from "react";
import {useParams, useNavigate, Link} from "react-router-dom";
import {ClubDetails, GetRegistrationClubDetails} from "../../services/registration";
import {toast} from "react-toastify";
import Loader from "../../components/loader";
import {
    Collapse, DropdownItem, DropdownMenu,
    DropdownToggle,
    Nav, Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown
} from "reactstrap";
import Logo from "../../assets/images/logo.png";
import classNames from "classnames";
import styles from "../home/index.module.scss";

const Registration = () => {
    const {club_domain} = useParams();
    const navigate = useNavigate();

    const [clubDetails, setClubDetails] = useState<ClubDetails | null>(null);
    const [clubDetailsLoading, setClubDetailsLoading] = useState(false);

    const fetchClubDetails = async () => {
        setClubDetailsLoading(true);
        await GetRegistrationClubDetails(`?clubDomain=${ club_domain }`).then(res => {
            setClubDetails(res?.data ?? null);
        }).catch(err => {
            const notify = () => toast.error("Unable to fetch details");
            notify();
        });
        setClubDetailsLoading(false);
    };

    useEffect(() => {
        if(club_domain) {
            fetchClubDetails();
        } else {
            navigate("/404");
        }
    }, [club_domain]);

    if(clubDetailsLoading) {
        return <Loader />
    }

    return <Fragment>
        <Navbar color="dark" dark expand={`md`}>
            <NavbarBrand href="/">
                <img
                    alt="logo"
                    src={Logo}
                    className={classNames(styles.navbar_img)}
                />
                { clubDetails?.ClubName }
            </NavbarBrand>
        </Navbar>
    </Fragment>
};

export default Registration;
