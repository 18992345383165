import React, { FC } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classnames from 'classnames';

import styles from './skeleton.module.scss';

const SkeletonPropTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    circle: PropTypes.bool,
};

type SkeletonProps = InferProps<typeof SkeletonPropTypes>;

const Skeleton: FC<SkeletonProps> = (props: SkeletonProps) => (
    <span className={classnames(styles.skeleton)}>
    &zwnj;
  </span>
);

export default Skeleton;
