import React, {Fragment, useEffect, useState} from "react";
import {
    Button,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input, Modal, ModalBody, ModalFooter, ModalHeader,
    Spinner,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import {
    faChevronRight,
    faCircle,
    faCircleNotch,
    faDownload,
    faEllipsisVertical, faMars,
    faShieldHalved,
    faTrashCan, faVenus
} from "@fortawesome/free-solid-svg-icons";
import {deleteMemberService, downloadMembersList, getMembersService, MemberItem} from "../../../../services/members";
import {capitalizeFirstLetter} from "../../../../helpers/StringFormatter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {table_style} from "../../../../helpers/TableHelper";
import Skeleton from "../../../../components/UI/skeleton";
import DataTable from 'react-data-table-component';
import Logo from "../../../../assets/images/logo.png";
import classNames from "classnames";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {BloodGroupEnum, bloodGroups, GenderEnum, genders, openWhatsApp} from "../../../../helpers/Constants";
import Moment from "moment-timezone";
import Filter from "../../../../components/UI/filter";
import {PhotoProvider, PhotoView} from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import {toast} from "react-toastify";
import styles from "./members.module.scss";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../../components/page/header";
import _ from "lodash";

const Members = () => {
    const navigate = useNavigate();
    const defaultFilters = {
        admin: false as boolean,
        bloodGroup: [] as BloodGroupEnum[],
        gender: [] as GenderEnum[]
    };

    /* States */
    const [membersList, setMembersList] = useState<MemberItem[]>([]);
    const [membersLoading, setMembersLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [searchLoading, setSearchLoading] = useState(false);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);

    const [filters, setFilters] = useState({...defaultFilters});
    const [filtersActive, setFiltersActive] = useState(false);
    const [downloadMembersLoading, setDownloadMembersLoading] = useState(false);

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [deleteMemberDetails, setDeleteMemberDetails] = useState<MemberItem | null>(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    /* APIs */
    const fetchMembersList = async () => {
        setMembersLoading(true);

        let params = `?requestType=1`;
        if (search || filters.bloodGroup.length > 0 || filters.gender.length > 0) {
            let bloodGroup = ``;
            if(filters.bloodGroup.length > 0) {
                bloodGroup = `&filterBloodGroup=${ encodeURIComponent(filters.bloodGroup.join(`,`)) }`;
            }

            let gender = ``;
            if(filters.gender.length > 0) {
                gender = `&filterGender=${ encodeURIComponent(filters.gender.join(`,`)) }`;
            }
            params = `?requestType=12&search=${search}${bloodGroup}${gender}`;
        }

        await getMembersService(params).then((response: any) => {
            setMembersList(response.data);
        }).catch(err => {
            setMembersList([]);
        });
        setMembersLoading(false);
        setSearchLoading(false);
        setFiltersActive(!_.isEqual(filters, defaultFilters));
    };
    const downloadMembersListHandler = async () => {
        setDownloadMembersLoading(true);
        await downloadMembersList(`?RequestType=1&ExportType=1&ActiveType=1`).then(res => {
            console.log(res);
            if(res?.filename) {
                window.open(`https://clubapps.in/data/club_1/export/members/${res?.filename}`, "_blank");
            }
            throw res;
        }).catch(err => {
            const notify = () => toast.error("Unable to download. Please try again after some time.");
            notify();
            console.log(err);
        });
        setDownloadMembersLoading(false);
    };
    const deleteFamilyHandle = async () => {
        if(!deleteMemberDetails) {
            return false
        }

        setDeleteLoading(true);
        const payload = {
            FamilyId: `${deleteMemberDetails.FamilyId}`,
            Type: 1
        }
        await deleteMemberService(payload).then(res => {
            if(res?.data) {
                setShowDeleteConfirmationModal(false);
                fetchMembersList();
                const notify = () => toast.success("Member deleted successfully.");
                notify();
            }
        }).catch(err => {
            const notify = () => toast.error("Unable to delete member. Please try again.");
            notify();
        });
        setDeleteLoading(false);
    };

    /* Filter Handles */
    const adminFilterHandle = async () => {
        // Inverts current admin state handler
        setFilters({
            ...filters,
            admin: !filters.admin
        });
    };
    const bloodGroupFilterHandle = async (label: BloodGroupEnum) => {
        // Accepts label as blood group label (Eg: A+, B+, etc)
        if (filters.bloodGroup.includes(label)) {
            setFilters({
                ...filters,
                bloodGroup: filters.bloodGroup.filter(filterBloodGroup => filterBloodGroup !== label)
            });
        } else {
            setFilters({
                ...filters,
                bloodGroup: [...filters.bloodGroup, label]
            });
        }
    };
    const genderFilterHandle = async (gender: GenderEnum) => {
        // Accepts gender as Male or Female
        if (filters.gender.includes(gender)) {
            setFilters({
                ...filters,
                gender: filters.gender.filter(genderItem => genderItem !== gender)
            });
        } else {
            setFilters({
                ...filters,
                gender: [...filters.gender, gender]
            });
        }
    };

    /* Use Effects */
    useEffect(() => {
        document.title = `Members${process.env.REACT_APP_CLUB_PORTAL}`;
        fetchMembersList();
    }, []);
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchMembersList();
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [search, filters]);

    /* Table Render */
    const returnTableColumns = () => {
        const rows = [
            {
                name: "#",
                selector: (row: MemberItem, index: number) => (index + 1) + ((page - 1) * pageSize),
                width: '65px',
                center: "true"
            },
            {
                name: "Photo",
                selector: (row: MemberItem) => {
                    return <PhotoProvider>
                        <PhotoView src={row?.ImageUrl || Logo}>
                            <img src={row?.ImageUrl || Logo} alt={row.Name} width={32} className={`bg-light rounded`} />
                        </PhotoView>
                    </PhotoProvider>
                },
                width: `80px`,
                center: "true"
            },
            {
                name: "Name",
                selector: (row: MemberItem) => {
                    return <div className={`d-flex align-items-center pointer-event`} onClick={() => navigate(`/members/${ row.FamilyId }`)}>
                        {row?.MemberAdmin === "1" && <p className={`mb-0 me-2`}>
                            <span className="fa-layers fa-fw" id={`user_${row.FamilyId}_${ row.MemberId }_admin`}>
                              <FontAwesomeIcon icon={faCircle} size='lg' className={`text-success`}/>
                              <FontAwesomeIcon icon={faShieldHalved} className={`text-white`} size={`xs`}/>
                            </span>
                            <UncontrolledTooltip target={`user_${row.FamilyId}_${ row.MemberId }_admin`} fade={false}>
                                Admin
                            </UncontrolledTooltip>
                        </p>}
                        <p className={`mb-0 text-primary`}>
                            {row?.NamePrefix || ""} {capitalizeFirstLetter(row.Name)}
                        </p>
                    </div>
                },
                width: '215px'
            },
            {
                name: "Mobile",
                selector: (row: MemberItem) => {
                    if (row.Mobile) {
                        return <div onClick={() => openWhatsApp(row.Mobile)}>
                            <p className={`mb-0`}>{row.Mobile} <FontAwesomeIcon className={`text-success ms-2`}
                                                                                size={"lg"} icon={faWhatsapp}/></p>
                        </div>
                    }
                    return <></>
                },
                width: '150px'
            },
            {
                name: "Spouse Name",
                selector: (row: MemberItem) => {
                    return <div className={`d-flex align-items-center pointer-event`} onClick={() => navigate(`/members/${ row.FamilyId }`)}>
                        {row?.SpouseAdmin === "1" && <p className={`mb-0 me-2`}>
                            <span className="fa-layers fa-fw">
                              <FontAwesomeIcon icon={faCircle} size='lg' className={`text-success`}/>
                              <FontAwesomeIcon icon={faShieldHalved} className={`text-white`} size={`xs`}/>
                            </span>
                        </p>}
                        <p className={`mb-0 text-primary`}>
                            {(row.HasPartner === "2" || row.HasPartner === "3") && !row?.SpouseName &&
                                <span className={`text-danger`}>Details Not Available!</span>}
                            {row?.SpouseNamePrefix || ""} {capitalizeFirstLetter(row?.SpouseName || "")}
                        </p>
                    </div>
                },
                width: '215px'
            },
            {
                name: "Spouse Mobile",
                selector: (row: MemberItem) => {
                    if (row.SpouseMobile) {
                        return <div onClick={() => openWhatsApp(row.SpouseMobile)}>
                            <p className={`mb-0`}>{row.SpouseMobile} <FontAwesomeIcon className={`text-success ms-2`}
                                                                                size={"lg"} icon={faWhatsapp}/></p>
                        </div>
                    }
                    return <></>
                },
                width: '150px'
            },
            {
                name: "Last Logged In",
                selector: (row: MemberItem) => {
                    return <p className={`mb-0`}>
                        { row?.LastLogin && Moment(row?.LastLogin).format(`MMM DD, YYYY hh:mm A`) }
                        { !row?.LastLogin && <span className={`text-danger`}>Not Logged In</span> }
                    </p>
                },
                width: '175px'
            },
            {
                name: 'Action',
                selector: (row: MemberItem) => {
                    return <UncontrolledDropdown>
                        <DropdownToggle color={`primary`} outline size={`sm`}>
                            More <FontAwesomeIcon icon={faEllipsisVertical}/>
                        </DropdownToggle>
                        <DropdownMenu container={document.body}>
                            <DropdownItem onClick={() => navigate(`/members/${row.FamilyId}`)}>View Details</DropdownItem>
                            <DropdownItem onClick={() => navigate(`/members/${row.FamilyId}/${row.MemberId}/modify`)}>Edit Details</DropdownItem>
                            <DropdownItem divider/>
                            <DropdownItem className={`text-danger`} onClick={() => {
                                setDeleteMemberDetails(row);
                                setShowDeleteConfirmationModal(true);
                            }}>
                                <FontAwesomeIcon icon={faTrashCan} size={"xs"} className={`me-1`}/> Delete Member
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                },
                width: '100px'
            },
            {
                name: '',
                selector: () => <Fragment>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Fragment>
            },
        ];

        if (search || filtersActive) {
            rows.splice(4, 1);
            rows.splice(4, 1);
        }

        return rows;
    }

    return <Container fluid className={`pt-3`}>
        <PageHeader
            title={`Members`}
            subtitle={<Fragment>
                <p>Total: {membersList.length}</p>
            </Fragment>}
            action={<Fragment>
                <Button color={`dark`} className={`me-2`} outline disabled={downloadMembersLoading} onClick={downloadMembersListHandler}>
                    {downloadMembersLoading && <Fragment><Spinner size={"sm"} className={`me-1`} /> Downloading...</Fragment>}
                    {!downloadMembersLoading && <Fragment><FontAwesomeIcon icon={faDownload} className={`me-1`} /> Download CSV</Fragment>}
                </Button>
                <Button color={`primary`}>
                    + Add Member
                </Button>
            </Fragment>}
        />
        <hr/>
        <div className={`pb-3 position-relative`}>
            <Input
                type={`search`}
                value={search}
                onChange={e => {
                    setMembersLoading(true);
                    setSearchLoading(true);
                    setSearch(e.target.value);
                }}
                placeholder={`Search Name, Mobile, Email, Blood Group, Occupation`}
            />
            {searchLoading && <span className={classNames(styles.search_loader)}>
                <FontAwesomeIcon icon={faCircleNotch} spin/> Searching ...
            </span>}
        </div>
        <div>
            <span>Filters:</span>
            <span className={`ms-2 mb-2`}>
                <Filter onClick={adminFilterHandle} active={filters.admin}>
                    <Fragment>
                        <FontAwesomeIcon icon={faShieldHalved}/> Admins
                    </Fragment>
                </Filter>
                <span className={`separator-v`}/>
                {bloodGroups.map(bloodGroup => {
                    return <Filter key={bloodGroup.value}
                                   onClick={() => bloodGroupFilterHandle(bloodGroup.label)}
                                   active={filters.bloodGroup.includes(bloodGroup.label)}
                    >
                        <Fragment>
                            {bloodGroup.label}
                        </Fragment>
                    </Filter>
                })}
                <span className={`separator-v`}/>
                {genders.map(gender => {
                    return <Filter key={gender.value}
                                   onClick={() => genderFilterHandle(gender.value)}
                                   active={filters.gender.includes(gender.value)}
                    >
                        <Fragment>
                            { gender.value === GenderEnum.male && <FontAwesomeIcon icon={faMars} className={classNames(styles.filter__male, `me-2`)} /> }
                            { gender.value === GenderEnum.female && <FontAwesomeIcon icon={faVenus} className={classNames(styles.filter__female, `me-2`)} /> }
                            { gender.label }
                        </Fragment>
                    </Filter>
                })}
            </span>
        </div>
        <hr/>
        {membersLoading && <Fragment>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <br/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
            <br/>
            <Skeleton/>
            <Skeleton/>
            <Skeleton/>
        </Fragment>}
        {!membersLoading && <DataTable
            // @ts-ignore
            columns={returnTableColumns()}
            data={membersList}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            paginationPerPage={pageSize}
            paginationRowsPerPageOptions={[10, 25, 50, 75, 100, 500]}
            progressPending={membersLoading}
            progressComponent={<Skeleton/>}
            pagination
            responsive
            paginationTotalRows={membersList.length}
            onChangePage={(page) => {
                setPage(page);
            }}
            onChangeRowsPerPage={(size) => {
                setPageSize(size);
            }}
            theme={"rheo"}
            persistTableHead
            customStyles={table_style}
            onRowClicked={(row: MemberItem) => {
                navigate(`/members/${ row.FamilyId }`);
            }}
        />}
        <Modal isOpen={showDeleteConfirmationModal}
               toggle={() => setShowDeleteConfirmationModal(false)}
               centered
               fade={false}
        >
            <ModalHeader toggle={() => setShowDeleteConfirmationModal(false)}>
                Delete Member?
            </ModalHeader>
            <ModalBody>
                <p>
                    You are about to permanently delete the member <strong className={`text-danger`}>{deleteMemberDetails?.Name}</strong>. This action cannot be undone. Are you sure you want to proceed with this deletion?
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color={`dark`} onClick={() => setShowDeleteConfirmationModal(false)}>
                    Cancel
                </Button>
                <Button color={`danger`} outline disabled={deleteLoading} onClick={deleteFamilyHandle}>
                    {deleteLoading && <span><Spinner size={"sm"} className={`me-1`}/> Deleting...</span>}
                    {!deleteLoading && <span>Delete</span>}
                </Button>
            </ModalFooter>
        </Modal>
    </Container>
};

export default Members;
