import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {ClubModuleItem, Home} from "../../store/store.types";
import {GetClubDetails, GetClubModules} from "../../services/dashboard";

export interface ClubDetailsResponse {

}

export interface ClubModulesResponse {
    code: string
    data: ClubModuleItem[]
    message: string
}
export const initialState: Home = {
    club: {
        loading: false,
        error: null,
        details: {

        },
    },
    modules: {
        loading: false,
        error: null,
        list: []
    }
};

export const getClubDetails = createAsyncThunk('club/details', async (regId: number, { rejectWithValue, dispatch }) => {
    return await GetClubDetails(regId).then(res => {
        return res;
    }).catch(err => {
        return rejectWithValue(err.response.data)
    });
});
export const getClubModulesList = createAsyncThunk('club/modules', async (payload: string, { rejectWithValue, dispatch }) => {
    return await GetClubModules().then(res => {
        return res;
    }).catch(err => {
        return rejectWithValue(err.response.data)
    });
});

const HomeSlice = createSlice({
    name: 'club',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getClubDetails.pending.type, (state: Home, action: PayloadAction<any>) => {
            state.club.details = true;
            state.club.error = "";
            state.club.details = {

            }
        })
        builder.addCase(getClubDetails.rejected.type, (state: Home, action: PayloadAction<any>) => {
            state.club.details = false;
            state.club.error = action.payload;
            state.club.details = {

            }
        })
        builder.addCase(getClubDetails.fulfilled.type, (state: Home, action: PayloadAction<ClubDetailsResponse>) => {
            state.club.details = false;
            state.club.error = null;
            state.club.details = action.payload
        })

        builder.addCase(getClubModulesList.pending.type, (state: Home, action: PayloadAction<any>) => {
            state.modules.loading = true;
            state.modules.error = "";
            state.modules.list = [];
        })
        builder.addCase(getClubModulesList.rejected.type, (state: Home, action: PayloadAction<any>) => {
            state.modules.loading = false;
            state.modules.error = action.payload;
            state.modules.list = [];
        })
        builder.addCase(getClubModulesList.fulfilled.type, (state: Home, action: PayloadAction<ClubModulesResponse>) => {
            state.modules.loading = false;
            state.modules.error = null;
            state.modules.list = action.payload.data;
        })
    }
});

export default HomeSlice.reducer;
