import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import RootStore from "../../store/store.types";
import {getClubModulesList} from "../../screens/home/home.slice";
import sidebarStyles from "./sidebar.module.scss";
import classNames from "classnames";
import Skeleton from "../UI/skeleton";
import {Link, useLocation, NavLink} from "react-router-dom";
import {Input} from "reactstrap";
import Logo from "../../assets/images/logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff, faUser} from "@fortawesome/free-solid-svg-icons";
import NamePrefix from "../UI/name-prefix";
import {pageRoutes} from "../../config/pages";

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const userDetails = useSelector((state: RootStore) => state.login.user.details);
    const modules = useSelector((state: RootStore) => state.home.modules.list);
    const modulesLoading = useSelector((state: RootStore) => state.home.modules.loading);

    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        dispatch(getClubModulesList("") as any);
    }, [dispatch]);

    return <div className={classNames(sidebarStyles.sidebar)}>
        <div className={classNames(sidebarStyles.sidebar__top)}>
            <div className={classNames(sidebarStyles.sidebar__logo)}>
                <img src={Logo} alt={"Logo"}/>
            </div>
            <div className={classNames(sidebarStyles.sidebar__menu)}>
                <div className={`p-2`}>
                    <Input type={`search`} placeholder={`Search`} value={search} onChange={(e) => {
                        setSearch(e.target.value);
                    }}/>
                </div>
                {
                    modulesLoading && <div>
                        <div className={classNames(sidebarStyles.sidebar__menu_item)}>
                            <Skeleton/>
                        </div>
                        <div className={classNames(sidebarStyles.sidebar__menu_item)}>
                            <Skeleton/>
                        </div>
                        <div className={classNames(sidebarStyles.sidebar__menu_item)}>
                            <Skeleton/>
                        </div>
                        <div className={classNames(sidebarStyles.sidebar__menu_item)}>
                            <Skeleton/>
                        </div>
                        <div className={classNames(sidebarStyles.sidebar__menu_item)}>
                            <Skeleton/>
                        </div>
                        <div className={classNames(sidebarStyles.sidebar__menu_item)}>
                            <Skeleton/>
                        </div>
                        <div className={classNames(sidebarStyles.sidebar__menu_item)}>
                            <Skeleton/>
                        </div>
                    </div>
                }
                {
                    modules.filter(module => {
                        return module.IsActive === "1" && pageRoutes?.[module.ModuleId];
                    }).filter(module => {
                        if (!search) {
                            return module;
                        }

                        return module.ModuleName.toLowerCase().includes(search.toLowerCase());
                    }).map((module) => {
                        return <NavLink to={`${pageRoutes?.[module.ModuleId]}`}
                                        className={({isActive}) => {
                                            return !isActive ? classNames(sidebarStyles.sidebar__menu_item) : classNames(sidebarStyles.sidebar__menu_item, sidebarStyles.sidebar__menu_item__active)
                                        }}
                                        key={module.ModuleId}>
                                <span className={classNames(sidebarStyles.sidebar__menu_item__title)}>
                                    <NamePrefix
                                        active={location.pathname.indexOf(`${pageRoutes?.[module.ModuleId]}`) > -1}
                                        text={module.ModuleName}
                                        randomBackground={false}/> {module.ModuleName}
                                </span>
                        </NavLink>
                    })
                }
            </div>
        </div>
        <div className={classNames(sidebarStyles.sidebar__footer)}>
            <Link to={`/profile`}
                  className={classNames(sidebarStyles.sidebar__menu_item, location.pathname === `/profile` ? sidebarStyles.sidebar__menu_item__active : '')}>
                    <span className={classNames(sidebarStyles.sidebar__menu_item__icon)}>
                        <FontAwesomeIcon icon={faUser}/>
                    </span>
                <span className={classNames(sidebarStyles.sidebar__menu_item__title)}>
                        {userDetails?.name || "-"}
                    </span>
            </Link>
            <Link to={`/logout`}
                  className={classNames(sidebarStyles.sidebar__menu_item, location.pathname === `/profile` ? sidebarStyles.sidebar__menu_item__active : '')}>
                    <span className={classNames(sidebarStyles.sidebar__menu_item__icon)}>
                        <FontAwesomeIcon icon={faPowerOff}/>
                    </span>
                <span className={classNames(sidebarStyles.sidebar__menu_item__title)}>
                        Logout
                    </span>
            </Link>
        </div>
    </div>
}

export default Sidebar;
