import React, {useState, useEffect, Fragment} from "react";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import {deleteMemberService, getMembersService, MemberDetailsItem} from "../../../../services/members";
import Skeleton from "../../../../components/UI/skeleton";
import IndividualMember from "../../../../components/modules/members/individualMember";
import classNames from "classnames";
import styles from "./styles.module.scss";
import PageHeader from "../../../../components/page/header";
import {PhotoProvider, PhotoView} from "react-photo-view";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";

const MemberDetails = () => {
    const { familyId } = useParams();
    const navigate = useNavigate();

    const [memberDetails, setMemberDetails] = useState<MemberDetailsItem[]>([]);
    const [memberDetailsLoading, setMemberDetailsLoading] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const fetchMemberFamilyDetails = async () => {
        setMemberDetailsLoading(true);
        await getMembersService(`?requestType=2&familyId=${ familyId }`).then(res => {
            console.log(res);
            setMemberDetails(res?.data || []);
        }).catch(err => {
            console.log(err);
        });
        setMemberDetailsLoading(false);
    };
    const deleteFamilyHandle = async () => {
        if(!familyId) {
            return false
        }

        setDeleteLoading(true);
        const payload = {
            FamilyId: familyId,
            Type: 1
        }
        await deleteMemberService(payload).then(res => {
            if(res?.data) {
                navigate(`/members`);
                const notify = () => toast.success("Member deleted successfully.");
                notify();
            }
        }).catch(err => {
            const notify = () => toast.error("Unable to delete member. Please try again.");
            notify();
        });
        setDeleteLoading(false);
    };

    useEffect(() => {
        if(familyId) {
            fetchMemberFamilyDetails();
        }
    }, []);

    if(memberDetailsLoading) {
        return <Container fluid className={`pt-3`}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <br/>
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </Container>
    }

    return <section className={`pt-3`}>
        <PageHeader
            title={`Members Details`}
            subtitle={<Fragment>
                <Breadcrumb tag={`div`}>
                    <BreadcrumbItem>
                        <span className={`text-primary pointer`} onClick={() => navigate(-1)}>
                            Members
                        </span>
                    </BreadcrumbItem>
                    <BreadcrumbItem active tag={`span`}>
                        { memberDetails?.[0]?.Name || "-" }
                    </BreadcrumbItem>
                </Breadcrumb>
            </Fragment>}
            onBack={() => {
                navigate(-1);
            }}
            action={<Fragment>
                <Button color={`danger`} onClick={() => setShowDeleteConfirmationModal(true)}>
                    <FontAwesomeIcon icon={faTrashCan} className={`me-2`} /> Delete Member
                </Button>
            </Fragment>}
        />
        <hr />
            { memberDetails?.[0]?.CoverUrl && <section className={`mb-3`}>
                <PhotoProvider>
                    <PhotoView src={memberDetails[0].CoverUrl}>
                        <div className={classNames(styles.family__cover)}
                             style={{
                                 backgroundImage: `url(${memberDetails[0].CoverUrl})`
                             }}
                        />
                    </PhotoView>
                </PhotoProvider>
            </section>}
        <section>
            <Row>
                {memberDetails.map(member => <Col md={6} key={`${member.FamilyId}_${member.MemberId}`}>
                    <IndividualMember item={member}/>
                </Col>) }
            </Row>
        </section>
        <Modal isOpen={showDeleteConfirmationModal}
               toggle={() => setShowDeleteConfirmationModal(false)}
               centered
               fade={false}
        >
            <ModalHeader toggle={() => setShowDeleteConfirmationModal(false)}>
                Delete Member?
            </ModalHeader>
            <ModalBody>
                <p>
                    You are about to permanently delete the member <strong className={`text-danger`}>{memberDetails?.[0]?.Name}</strong>. This action cannot be undone. Are you sure you want to proceed with this deletion?
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color={`dark`} onClick={() => setShowDeleteConfirmationModal(false)}>
                    Cancel
                </Button>
                <Button color={`danger`} outline disabled={deleteLoading} onClick={deleteFamilyHandle}>
                    {deleteLoading && <span><Spinner size={"sm"} className={`me-1`}/> Deleting...</span>}
                    {!deleteLoading && <span>Delete</span>}
                </Button>
            </ModalFooter>
        </Modal>
    </section>
};

export default MemberDetails;
