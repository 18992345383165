export const table_style = {
    head: {
        style: {
            fontSize: '16px',
            fontWeight: 600
        }
    },
    headCells: {
        style: {
            paddingTop: '15px',
            paddingBottom: '15px',
            background: "#E9ECEF",
            borderLeft: `0.5px solid #dee2e6`,
            borderRight: `0.5px solid #dee2e6`
        },
    },
    cells: {
        style: {
            borderLeft: `0.5px solid #dee2e6`,
            borderRight: `0.5px solid #dee2e6`
        },
    },
};
